import { Tracker } from '../../types';
import { getOutbrainEvent } from './utils';

declare function obApi(
    command: 'track',
    eventName: string
    // data?: Record<string, unknown>
): void;

export function createOutbrainTracker(): Tracker {
    return {
        name: 'outbrainTracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            const outbrainEventData = getOutbrainEvent(event);
            if (outbrainEventData) {
                const { eventName } = outbrainEventData;
                console.log(
                    '%cOutbrainEvent: ' +
                        eventName +
                        ' ' +
                        JSON.stringify(opts.data),
                    'background: #222; color: #bada55'
                );

                // Not clear today if params are supported by Outbrain yet. We will revisit this if needed.
                // if (opts.data) {
                //     obApi('track', eventName, opts.data);
                // }

                obApi('track', eventName);
            }
        },
        release() {
            // do nothing
        },
    };
}
