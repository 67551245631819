import type { BaseThemePrepared } from '@archipro-design/aria';
import { baseTheme, ecomLandingTheme } from '@archipro-design/aria';

export const getThemeByUrl = (pathname: string): BaseThemePrepared => {
    const basePath = pathname.split('/')[1] ?? 'default';

    switch (basePath) {
        case 'ecommerce-launch':
            return ecomLandingTheme;
        case 'search':
        case 'professional':
        case 'professionals':
        case 'project':
        case 'projects':
        case 'product':
        case 'products':
        case 'article':
        case 'articles':
        case 'welcome':
        case 'digital-magazines':
        case 'digital-magazine':
        case '':
        default:
            return baseTheme;
    }
};
