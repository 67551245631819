import {
    Links,
    Meta,
    Outlet,
    Scripts,
    isRouteErrorResponse,
    useLocation,
    useRouteError,
} from '@remix-run/react';
import {
    getThemeByUrl,
    useMatchesHandleData,
    useRootData,
} from '@modules/root';

import {
    DeviceBrowserContextProvider,
    DeviceTypeContextProvider,
    AppDisplayModeContextProvider,
    Provider,
    baseTheme,
    RendererProvider,
} from '@archipro-design/aria';
import type { LinksFunction } from '@remix-run/node';
import { useHydrated } from 'remix-utils';
import { BodyPixels, HeadPixels } from '@modules/tracking';
import ErrorPage from '../../error/page/ErrorPage';
import type { ReactNode } from 'react';
import { ConfigProvider } from '@archipro-website/config/bindings/react';
import { CountryProvider } from '@archipro-website/localisation';
import CustomScrollRestoration from '@modules/root/component/scroll-restoration/CustomScrollRestoration';
import { processTheme } from '@modules/root';
import { LocalisationContextProvider } from '@archipro-website/localisation';
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useRenderType } from '~/render-context';
import { useMockDevice } from '@modules/root/hook/use-mock-device';
import { DevicePreview } from '@modules/root/component/device-preview/DevicePreview';
import { TrackerContext, useArchiproTracking } from '@archipro-website/tracker';
import type { GrowthBookSSRData } from '@growthbook/growthbook-react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { useGrowthbookClient } from '../hook/use-growthbook';
import Debug from '~/modules/error/component/debug/Debug';
import { createFelaRendererFactory } from '../../../../react-northstar-fela-renderer/src/createFelaRenderer';
import { useFluentContext } from '@fluentui/react-northstar';
import { useSetAtom } from 'jotai';
import { _displayModeAtom } from '@rocco/states/uiContextAtomDisplayMode';

/**
 * Site wide fonts and favicons.
 */
export const links: LinksFunction = () => {
    return [
        {
            rel: 'icon',
            href: '/assets/website/ui/favicon-rocco2/favicon.svg',
            type: 'image/svg+xml',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/assets/website/ui/favicon-rocco2/favicon-16x16.png',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/assets/website/ui/favicon-rocco2/favicon-32x32.png',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '96x96',
            href: '/assets/website/ui/favicon-rocco2/favicon-96x96.png',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '144x144',
            href: '/assets/website/ui/favicon-rocco2/android-chrome-144x144.png',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '192x192',
            href: '/assets/website/ui/favicon-rocco2/android-chrome-192x192.png',
        },
        {
            rel: 'icon',
            type: 'image/png',
            sizes: '512x512',
            href: '/assets/website/ui/favicon-rocco2/android-chrome-512x512.png',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '120x120',
            href: '/assets/website/ui/favicon-rocco2/apple-touch-icon-120x120.png',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '152x152',
            href: '/assets/website/ui/favicon-rocco2/apple-touch-icon-152x152.png',
        },
        {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/assets/website/ui/favicon-rocco2/apple-touch-icon-180x180.png',
        },
    ];
};

/**
 * This component manages meta and link tags in the head.
 * Since we are only hydrating id=root in the body, we need to
 * use createPortal to render the head tags. on spa navigation.
 */
const HeadPortal = ({ children }: { children: ReactNode }) => {
    const [innerHtmlEmptied, setInnerHtmlEmptied] = React.useState(false);
    const tagsToRemove = React.useRef<HTMLElement[]>(
        Array.from(
            document.head.querySelectorAll(
                'meta,link,title,script[type="application/ld+json"]'
            )
        )
    );
    React.useLayoutEffect(() => {
        /**
         * we only need to do this once.
         * remove meta and link tags from the initial head or they would
         * be duplicated since the
         *
         */
        if (!innerHtmlEmptied) {
            const removeHeadElement = (elements: HTMLElement) => {
                /**
                 * make sure it's still there before removing it
                 * or it will throw an exception
                 */
                if (document.head.contains(elements)) {
                    document.head.removeChild(elements);
                }
            };
            tagsToRemove.current.forEach(removeHeadElement);
            setInnerHtmlEmptied(true);
        }
    }, [innerHtmlEmptied]);

    if (!innerHtmlEmptied) return null;

    return createPortal(children, document.head);
};

/**
 * Full body of the app rendered on the server and the browser.
 */
const Body = ({ children }: { children: ReactNode }) => {
    const {
        device,
        localisation,
        config,
        appDisplayMode,
        user,
        growthbookContext,
    } = useRootData();

    const location = useLocation();
    const hydrated = useHydrated();
    const mockDevice = useMockDevice(location.search);

    // set the display mode for using in rocco UI components
    const setDisplayMode = useSetAtom(_displayModeAtom);
    setDisplayMode(appDisplayMode);

    if (mockDevice) {
        device.type = mockDevice;
    }

    const theme = processTheme(
        getThemeByUrl(location.pathname),
        device.type === 'desktop'
    );

    // Sets up all of our different tracking pixels
    const tracker = useArchiproTracking(location, user, {
        ga4TrackingId: config.pixels.ga4TrackingId,
    });

    const gb = useGrowthbookClient(
        growthbookContext as GrowthBookSSRData,
        tracker
    );

    return (
        <div
            style={{
                backgroundColor: theme.siteVariables.bodyBackground,
            }}
        >
            {mockDevice === undefined && <BodyPixels {...config.pixels} />}
            {/* //fallback to original theme when its in originalDefaultWindow width */}

            <TrackerContext.Provider value={tracker}>
                <GrowthBookProvider growthbook={gb}>
                    <Provider theme={theme}>
                        {import.meta.env.DEV ? <Debug /> : null}
                        <LocalisationContextProvider
                            data={localisation}
                            hydrated={hydrated}
                        >
                            <DeviceTypeContextProvider device={device.type}>
                                <DeviceBrowserContextProvider
                                    browser={device.browser}
                                >
                                    <AppDisplayModeContextProvider
                                        mode={mockDevice ?? appDisplayMode}
                                    >
                                        <CountryProvider
                                            code={localisation.country}
                                        >
                                            <ConfigProvider config={config}>
                                                {mockDevice ? (
                                                    <DevicePreview>
                                                        {children}
                                                    </DevicePreview>
                                                ) : (
                                                    <>{children}</>
                                                )}
                                            </ConfigProvider>
                                        </CountryProvider>
                                    </AppDisplayModeContextProvider>
                                </DeviceBrowserContextProvider>
                            </DeviceTypeContextProvider>
                        </LocalisationContextProvider>
                    </Provider>
                </GrowthBookProvider>
            </TrackerContext.Provider>
            <CustomScrollRestoration />
            <Scripts />
        </div>
    );
};

/**
 * Full head
 */
const Head = () => {
    const { device } = useRootData();
    const disableMobileUserScaling = useMatchesHandleData<boolean>(
        'disableMobileUserScaling',
        false
    );

    const noUserScalable =
        device.type !== 'desktop' && disableMobileUserScaling;
    const viewport = noUserScalable
        ? 'width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no'
        : device?.os.isIOS
          ? 'width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover'
          : 'width=device-width,initial-scale=1';

    return (
        <>
            <meta charSet="utf-8" />
            <meta name="viewport" content={viewport} />
            {noUserScalable && (
                <meta name="HandheldFriendly" content="true"></meta>
            )}
            <Meta />
            <Links />
        </>
    );
};

/**
 * This is version of tghe head that `should` not throw errors.
 *
 */
const SafeHead = () => {
    return (
        <>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width,initial-scale=1"
            />
            <Links />
        </>
    );
};

/**
 * This is the Frankenstein of the app.
 * entry.server and entry.client proive the RenderContext to
 * pass information about the `type` of render we are doing.
 */
const RootPage = () => {
    const { config } = useRootData();
    const renderType = useRenderType();
    const location = useLocation();
    const mockDevice = useMockDevice(location.search);

    switch (renderType) {
        /**
         * important server and browser `MUST` render the exat same component tree
         * so that useId can provide consistent ids across the app.
         */
        case 'server-body':
        case 'browser-body':
            return (
                <Body>
                    <Outlet />
                </Body>
            );
        /**
         * render pixels only once form the server.
         */
        case 'server-head':
            return (
                <>
                    <Head />
                    {mockDevice === undefined && (
                        <HeadPixels {...config.pixels} />
                    )}
                </>
            );
        /**
         * we use a portal to `by-pass` the hydration matching process
         */
        case 'browser-head':
            return (
                <HeadPortal>
                    <Head />
                </HeadPortal>
            );
    }
};

export const ErrorBoundary = () => {
    const renderType = useRenderType();
    const error = useRouteError();
    const rootData = useRootData();

    const fluentUI = useFluentContext();
    // Slight hack for detecting whether the renderer is available
    // This is usually caused by the root loader failing and thus
    // not instantiating the renderer.
    // NoopProvider is the default renderer in the context.
    const isNoopProvider = fluentUI.renderer.Provider.name === 'NoopProvider';
    const { current: createFelaRenderer } = useRef(
        createFelaRendererFactory().create
    );
    const renderWithRenderer = (element: JSX.Element) => {
        if (!isNoopProvider) {
            return element;
        }

        return (
            <RendererProvider value={createFelaRenderer}>
                <Provider theme={baseTheme}>{element}</Provider>
            </RendererProvider>
        );
    };

    /**
     * there is an error in the page. When rendering the head just render the
     * `SafeVersion` one that doesn't use Meta or DyanmicLinks which are vulnerable to errors.
     */
    if (renderType === 'server-head') {
        return <SafeHead />;
    }
    if (renderType === 'browser-head') {
        return (
            <HeadPortal>
                <SafeHead />
            </HeadPortal>
        );
    }

    let errorObj = new Error('Unknown error');

    // If the error is a route error, we can extract the error object from it or create it if it doesn't exist
    // We shoudn't get route errors as the data is coming from the remix-context, so the error will be captured on the remix-server
    // but we have this just in case
    if (isRouteErrorResponse(error)) {
        errorObj =
            error.data.message || new Error(`${error.status}: ${error.data}`);
    } else if (error instanceof Error) {
        errorObj = error;
    }

    const errorPage = <ErrorPage error={errorObj} />;
    // Avoid using this <Body> as a wrapper, as some errors cause the root loader to fail..
    // like for example someone POSTing to a route that doesn't accept a POST request (no action defined)
    if (!rootData) {
        return renderWithRenderer(errorPage);
    }

    return renderWithRenderer(<Body>{errorPage}</Body>);
};

export default RootPage;
