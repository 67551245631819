import { Tracker } from '../../types';
import { isMember } from '../../utils/isMember';
import { TrackedGuest } from '../archiproTracker';

interface FeatureFlagType {
    Enabled: boolean;
    Name: string;
}

declare global {
    interface Window {
        gtag: (
            command: string,
            action: string, // Can be a target_id, conversion, a property, or otherwise
            options: Record<string, unknown>
        ) => void;
        dataLayer: Record<string, unknown>[]; // Google Data Layer
    }
}

// This array maps the DB professionalType values into the Hubspot values
export const professionalCategoryToHubspotMapping = {
    architect: 'Architect',
    architectural: 'Architectural Designer',
    builder: 'Builder',
    contractor: 'Contractor',
    designer: 'Interior Designer',
    engineer: 'Engineer',
    industry: 'Industry Associations',
    kitchen: 'Kitchen Designer',
    landscaper: 'Landscape Architect',
    manufacturer: 'Manufacturer',
    photographer: 'Photographer',
    timber: 'Timber Joiner',
};

interface TrackedMember {
    ID: number;
    __typename: 'Me';
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    ProfessionalType: string;
    IsArchiproStaff: boolean;
    IntercomHash: string;
    FeatureFlags: FeatureFlagType[];
    TeamMembers: Array<{ Permissions: string[] }>;
    Professionals: Array<{
        Title: string;
        ID: number;
        URLSegment: string;
        IsManufacturer: boolean;
        MetaData: {
            AverageScore: number;
        };
        BusinessFocus: string;
        Country: string;
        ServiceableAreasCount: number;
        TradePricing: Array<{
            ID: number;
            TradeProductRate: number;
            TradeSampleRate: number;
        }>;
        AnalyticsEnabled: boolean;
        EcommerceEnabled: boolean;
        AnalyticsStatus: string;
        Website: string;
        StripeAccountEnabled: boolean;
        LibraryLocationsCount: number;
        HasEcommerceEmail: boolean;
        PrimaryDomain: string;
    }>;
}

export type TrackedUser = TrackedMember | TrackedGuest;

export function createGTMTracker(user: TrackedUser): Tracker {
    if (isMember<TrackedMember>(user)) {
        const ProfessionalData = user.Professionals?.length
            ? {
                  company: user.Professionals[0].Title,
                  userCompanyID: user.Professionals[0].ID,
                  userCompanyURL: user.Professionals[0].URLSegment,
                  isCompanyProductSupplier:
                      user.Professionals[0].IsManufacturer,
                  isCompanyEcommerceEnabled:
                      user.Professionals[0].EcommerceEnabled,
                  userPermissions: user.TeamMembers?.length
                      ? user.TeamMembers[0].Permissions.toString()
                      : '',
                  averageProductScore:
                      user.Professionals[0].MetaData?.AverageScore ?? undefined,
                  companyFocus: user.Professionals[0].BusinessFocus,
                  companyCountryID: user.Professionals[0].Country,
                  serviceableAreasCount:
                      user.Professionals[0].ServiceableAreasCount > 0
                          ? user.Professionals[0].ServiceableAreasCount
                          : undefined,
                  hasTradePricing:
                      user.Professionals[0].TradePricing?.length > 0,
                  hasAnalyticsEnabled: user.Professionals[0].AnalyticsEnabled,
                  analyticsStatus: user.Professionals[0].AnalyticsStatus,
                  companyWebsite: user.Professionals[0].Website,
                  isStripeAccountEnabled:
                      user.Professionals[0]?.StripeAccountEnabled,
                  libraryLocationsCount:
                      user.Professionals[0]?.LibraryLocationsCount,
                  hasEcommerceEmailAddress:
                      user.Professionals[0]?.HasEcommerceEmail,
              }
            : {};

        const intercomProfessionalsArray = user.Professionals?.length
            ? user.Professionals.map(professional => {
                  return {
                      company_id: professional.ID,
                      name: `${professional.Title} ${professional.Country}`,
                      company_url_segment_gtm: professional.URLSegment,
                      is_product_supplier: professional.IsManufacturer,
                      website: professional.Website,
                      ecommerce_enabled_gtm: professional.EcommerceEnabled,
                      company_country_gtm: professional.Country,
                      live_product_score: professional.MetaData?.AverageScore,
                      company_focus_gtm: professional.BusinessFocus,
                      company_serviceable_areas_gtm:
                          professional.ServiceableAreasCount,
                      company_has_trade_pricing_gtm:
                          professional.TradePricing?.length > 0,
                      company_domain_url_gtm: professional.PrimaryDomain,
                      company_business_analytics_status_gtm:
                          professional.AnalyticsStatus,
                      stripe_enabled_gtm: professional.StripeAccountEnabled,
                      library_locations_count_gtm:
                          professional.LibraryLocationsCount,
                      has_ecommerce_email_address_gtm:
                          professional.HasEcommerceEmail,
                  };
              })
            : {};

        // Tracker scripts are loaded on the client only. e.g GTM
        // We need to make sure any window objects are defined if they are accessed in the first render
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            userID: user.ID,
            userType: user.__typename,
            firstname: user.FirstName,
            lastname: user.LastName,
            email: user.Email,
            phone: user.Phone,
            isStaffAdmin: user.IsArchiproStaff,
            industry_category: user.ProfessionalType
                ? professionalCategoryToHubspotMapping[
                      user.ProfessionalType as keyof typeof professionalCategoryToHubspotMapping
                  ]
                : '',
            intercomHash: user.IntercomHash,
            featureFlags: user.FeatureFlags,
            ...ProfessionalData,
            intercom_companies: intercomProfessionalsArray,
        });
    }

    return {
        name: 'googleTagManagerTracker',
        // eslint-disable-next-line @typescript-eslint/require-await
        async log(event, opts) {
            const url = opts.url;

            // Copypasta from old tracker.
            // This was set up to avoid some sort of bug that occurs
            const strippedDataLayer = window.dataLayer || [];
            strippedDataLayer.forEach((dataObject, index) => {
                if (dataObject['eventLabel'] === 'Dentsu') {
                    strippedDataLayer.splice(index, 1);
                }
            });
            window.dataLayer = strippedDataLayer;

            const extraData = opts.gtmData || {};

            window.dataLayer.push({
                event: event,
                pagePath: url.pathname + url.search,
                pageTitle: document.title,
                eventData: opts.data || {},
                eventLabel: opts.label || '',
                ...extraData,
            });
        },
        release() {
            // do nothing
        },
    };
}
