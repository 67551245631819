import { TrackingEvent } from '../../types';

interface OutbrainEventData {
    eventName: string;
}

export function getOutbrainEvent(
    event: TrackingEvent
): OutbrainEventData | null {
    let outbrainEvent: string | null = null;

    switch (event) {
        // Page Views
        case 'PageView':
            outbrainEvent = 'PAGE_VIEW'; // Outbrain standard event
            break;

        case 'PerformSearch':
            outbrainEvent = 'Search'; // Outbrain standard event
            break;

        case 'AuthSignUpSubmit':
            outbrainEvent = 'Registration'; // Outbrain standard event
            break;

        // Conversions
        case 'DownloadsClick':
            outbrainEvent = 'Download'; // Outbrain standard event
            break;

        case 'EnquirySubmit':
            outbrainEvent = 'Lead'; // Outbrain standard event
            break;
        case 'Website':
            outbrainEvent = 'WebsiteReferral'; // Outbrain custom event
            break;
        case 'SaveSubmit':
            outbrainEvent = 'Save'; // Outbrain custom event
            break;
        case 'PhoneReveal':
            outbrainEvent = 'PhoneReveal'; // Outbrain custom event
            break;

        case 'BookConsultationReveal':
            outbrainEvent = 'BookConsultationReveal'; // Outbrain custom event
            break;

        // Ecom
        case 'AddToCart':
            outbrainEvent = 'addtocart'; // Pinterest standard event
            break;
        case 'InitiateCheckout':
            outbrainEvent = 'Checkout'; // Outbrain standard event
            break;
        case 'Purchase':
            outbrainEvent = 'Purchase'; // Outbrain standard event
            break;
    }

    if (!outbrainEvent) {
        return null;
    }

    return {
        eventName: outbrainEvent,
    };
}
